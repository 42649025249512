import { useRouter } from "next/router";
import {
  Button,
  Link as LinkUi,
  VideoObjectProps,
} from "@business-finland/wif-ui-lib";
import { Hero as HeroUi } from "@business-finland/wif-ui-lib/dist/Hero";

import useViewport from "../../../contexts/ViewportContext";
import useEmployerSite from "../../../contexts/EmployerSiteContext";
import useImageDataHandler from "../../../hooks/useImageDataHandler";
import {
  formatPageLink,
  getAssetPath,
  jcrMultiFieldToArray,
} from "../../../util/magnolia";

export type IHeroAssetProps =
  | {
      field: "video";
      videoXS: Wif.Mgnl.Video;
      videoSM: Wif.Mgnl.Video;
      videoLG: Wif.Mgnl.Video;
      videoAltText?: string;
    }
  | {
      field: "image";
      image: Wif.Mgnl.Image;
      imageAltText?: string;
    };
export interface IHeroButtonProps {
  text: string;
  link: string;
  type: "primary" | "secondary";
  anchorTag?: string;
}

export interface IHeroProps {
  heading: string;
  asset: IHeroAssetProps;
  buttons: Wif.Mgnl.NodeWithList<Wif.Mgnl.Node<IHeroButtonProps>>;
  leadText?: string;
}

export function Hero({
  heading,
  leadText,
  asset,
  buttons,
}: IHeroProps): JSX.Element {
  const router = useRouter();
  const viewport = useViewport();
  const { isEmployerSite } = useEmployerSite();
  const imageDataHandler = useImageDataHandler();

  const HeroButton = isEmployerSite ? Button.HeroEmployer : Button.HeroTalents;
  const buttonObjects = jcrMultiFieldToArray(buttons);
  const buttonLinks = buttonObjects.map((button: IHeroButtonProps) => {
    const link = formatPageLink(
      button.link,
      router?.isPreview,
      button.anchorTag
    );

    return (
      <LinkUi
        className={Button.DefaultFocusStyleInLink}
        href={link}
        key={button.text}
        disableLinkTextStyle={true}
        itemProp="url"
      >
        <HeroButton asDiv text={button.text} />
      </LinkUi>
    );
  });

  if (asset.field === "video") {
    const { videoXS, videoSM, videoLG, videoAltText } = asset;

    const videoSrc: VideoObjectProps[] = [
      {
        src: getAssetPath(videoXS, router.isPreview),
        mimeType: videoXS.metadata?.mimeType,
        device: ["xs"],
        credits: videoXS?.metadata?.rights,
      },
      {
        src: getAssetPath(videoSM, router.isPreview),
        mimeType: videoSM.metadata?.mimeType,
        device: ["sm", "md"],
        credits: videoSM?.metadata?.rights,
      },
      {
        src: getAssetPath(videoLG, router.isPreview),
        mimeType: videoLG.metadata?.mimeType,
        device: ["lg", "xl"],
        credits: videoLG?.metadata?.rights,
      },
    ];

    return (
      <div>
        <HeroUi
          assetType={asset.field}
          heading={heading}
          leadText={leadText}
          video={videoSrc}
          viewport={viewport}
          videoAltText={videoAltText}
          buttons={buttonLinks}
        />
      </div>
    );
  }

  const { image, imageAltText } = asset;
  const imageData = imageDataHandler(image);

  return (
    <div>
      <HeroUi
        imageData={{ ...imageData, imageSrc: imageData.src }}
        assetType={asset.field}
        heading={heading}
        leadText={leadText}
        imageAltText={imageAltText}
        buttons={buttonLinks}
        credits={image?.metadata?.rights}
      />
    </div>
  );
}
